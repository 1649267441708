import React from 'react'
import Navbar from './Navbar'


export default function Header() {
  return (
    <div className='Header'>
      <Navbar/>
 
    </div>
  )
}